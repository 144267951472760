import React from "react";
import classNames from "classnames";
import { useAppState } from "@state";
import { Text, Image, Icon } from "@atoms";
import { AppLink } from "@base";

const ListItem = ({
  className: _className,
  description,
  image,
  title,
  url,
}) => {
  const [, dispatch] = useAppState();

  return (
    <AppLink
      to={url}
      onClick={() => {
        dispatch({ type: "closeModal" });
      }}
      className="group block"
    >
      <div
        className={classNames("flex flex-wrap items-start gap-4", _className)}
      >
        <div
          className={classNames(
            "relative aspect-[4/3] w-full flex-shrink-0 sm:w-40",
            {
              "bg-gray": !image,
            }
          )}
        >
          {!image && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Icon
                name="logoIcon"
                className="absolute w-1/5 max-w-[6rem] text-green md:w-1/3"
              />
            </div>
          )}
          <Image image={image} fill />
        </div>
        <div className="flex flex-1 flex-col gap-4">
          <Text className="font-bold duration-300 group-hover:text-green">
            {title}
          </Text>
          <Text variant="sm">{description}</Text>
        </div>
      </div>
    </AppLink>
  );
};

ListItem.defaultProps = {};

export default ListItem;
